import { useMonthEndClosureVM } from "presentation/hook/MonthEndClosure/useMonthEndClosureVM";
import { useMonthEndClosureTracked } from "presentation/store/MonthEndClosure/MonthEndClosureProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import MonthEndActivityLogModal from "./ActivityLog/MonthEndActivityLogModal";
import MonthEndClosureEditPanel from "./EditPanel/MonthEndClosureEditPanel";
import ReservationItemEditPanel from "./EditPanel/ReservationItemEditPanel";
import MonthEndClosureTablePanel from "./Table/MonthEndClosureTablePanel";
import ReservationItemTablePanel from "./Table/ReservationItemTablePanel";


const MonthEndClosureMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const monthEndClosureVM = useMonthEndClosureVM();
    const [monthEndClosureState] = useMonthEndClosureTracked();
    const { isShowDtlPanel, isShowUpdatePanel, isAddReservation, isEditReservation, isShowActivityLogPanel } = monthEndClosureState;

    useEffect(() => {
        const initialScreen = async () => {
            try {
                setIsLoading(true);
                const results = await Promise.allSettled([
                    monthEndClosureVM.loadDropdownOption(),
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            monthEndClosureVM.initMonthEndClosureList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [monthEndClosureVM])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper${(isAddReservation || isEditReservation || isShowUpdatePanel) ? '' : ' im-hide-side-form-draggable'}`}>
            {!(isShowDtlPanel) && <>
                {/* <MonthEndClosureTitleBar /> */}
                <SliderPanel
                    isOpen={true}
                    draggable={false}
                    leftSectionWidth={isShowUpdatePanel ? "70%" : "100%"}
                    rightSectionWidth={isShowUpdatePanel ? "30%" : "0%"}
                    leftChildren={<MonthEndClosureTablePanel />}
                    rightChildren={<MonthEndClosureEditPanel monthEndClosureState={monthEndClosureState} />}
                />
                {isShowActivityLogPanel && <MonthEndActivityLogModal />}
            </>}
            {(isShowDtlPanel) && <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={(isAddReservation || isEditReservation) ? "70%" : "100%"}
                rightSectionWidth={(isAddReservation || isEditReservation) ? "30%" : "0%"}
                leftChildren={<ReservationItemTablePanel monthEndClosureState={monthEndClosureState} />}
                rightChildren={<ReservationItemEditPanel monthEndClosureState={monthEndClosureState} />}
            />}
        </div>
    </>
}

export default memo(MonthEndClosureMaintenance);