import { CustomCellRendererProps } from "ag-grid-react";
import { DECIMAL_2 } from "presentation/utils/numberUtil";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { MonthEndClosureConstant } from "./MonthEndClosureConstant";

const SCREEN_CONSTANT = MonthEndClosureConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_MONTH_END_CLOSURE_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.YEAR,
        field: "year",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        dataType: "number",
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.MONTH,
        field: "month",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
        dataType: "number",
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.FROM_DATE,
        field: "fromDate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
        dataType: "date"
    },
    {
        headerName: SCREEN_CONSTANT.TO_DATE,
        field: "toDate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
        dataType: "date"
    },
    {
        headerName: SCREEN_CONSTANT.STATUS,
        field: "status",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
    },
    {
        headerName: SCREEN_CONSTANT.TURNOVER,
        field: "turnover",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.ESTIMATION,
        field: "estimation",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
    },
    {
        headerName: SCREEN_CONSTANT.UPDATE_DATETIME,
        field: "updatedDateTime",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
        dataType: "dateTime"
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, [], [], [], cellRenderers, numberFieldList);
});
export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}